<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-text-field
      v-model.number="localValue"
      type="number"
      filled
      single-line
      :name="name"
      :error-messages="errors[0]"
    />
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style module lang="scss"></style>
