<template>
  <v-row v-if="show" dense>
    <v-col
      v-if="label"
      cols="5"
      class="d-flex align-center pa-0"
      :class="$style['col-label']"
    >
      <Label
        :label="label"
        :name="name"
        :show="isRequired || isItemRequired"
        :verticalAlign="labelVerticalAlign"
      />
    </v-col>
    <v-col cols="7" class="pa-0"
      :class="$style['col-input']">
      <template v-if="type === 'file'">
        <FileField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :display-stored-file="displayStoredFile"
          :stored-file="storedFile"
          :width="width"
        />
      </template>
      <template v-else-if="type === 'textarea'">
        <TextareaField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :width="width"
        />
      </template>
      <template v-else-if="type === 'select'">
        <SelectField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :options="optionType != null ? options : selects"
          :text-key="textKey"
          :value-key="valueKey"
          :multiple="multiple"
          :limit="limit"
          :width="width"
          :hide-details="hideDetails"
          :useClear="useClear"
          :placeholder="placeholder"
          :background-color="backgroundColor"
        />
      </template>
      <template v-else-if="type === 'date'">
        <DateField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :width="width"
        />
      </template>
      <template v-else-if="type === 'number'">
        <NumberField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :width="width"
        />
      </template>
      <template v-else-if="type === 'radio'">
        <RadioField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :options="optionType != null ? options : selects"
          :text-key="textKey"
          :value-key="valueKey"
          :width="width"
        />
      </template>
      <template v-else-if="type === 'display'">
        <DisplayField
          :value="localValue"
          :render="render"
          :as="as"
          :option="option"
        />
      </template>
      <template v-else-if="type === 'password'">
        <PasswordField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :ref-name="refName"
          :width="width"
          :hide-details="hideDetails"
          :background-color="backgroundColor"
        />
      </template>
      <template v-else-if="type === 'custom'">
        <slot :name="name" />
      </template>
      <template v-else>
        <TextField
          v-model="localValue"
          :name="name"
          :label="label"
          :rules="rules"
          :width="width"
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :background-color="backgroundColor"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import Label from "./label";
import FileField from "./file";
import TextareaField from "./textarea";
import SelectField from "./select";
import DateField from "./date";
import NumberField from "./number";
import RadioField from "./radio";
import TextField from "./text";
import DisplayField from "./display";
import PasswordField from "./password";

export default {
  components: {
    Label,
    FileField,
    TextareaField,
    SelectField,
    DateField,
    NumberField,
    RadioField,
    TextField,
    DisplayField,
    PasswordField,
  },
  props: {
    value: {
      required: true
    },
    type: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object]
    },
    refName: {
      type: String
    },
    optionType: {
      type: [String, Array]
    },
    textKey: {
      type: String
    },
    valueKey: {
      type: String
    },
    multiple: {
      type: Boolean
    },
    limit: {
      type: Number
    },
    as: {
      type: String,
      default: "default"
    },
    render: {
      type: Function,
      default: (value) => value
    },
    option: {
      type: Function,
      default: () => {}
    },
    displayStoredFile: {
      type: Boolean,
      default: false
    },
    storedFile: {
      type: String,
      default: ""
    },
    rowMinHeight: {
      type: String,
      default: "60px"
    },
    show: {
      type: Boolean,
      default: true
    },
    width: {
      type: String
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    useClear: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    labelVerticalAlign: {
      type: String
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    options() {
      return this.$store.getters[`${this.optionType}/options/options`] || [];
    },
    isRequired() {
      return ((this.rules || "").match(/required/g) || []).length > 0;
    },
    isItemRequired() {
      return ((this.itemRules || "").match(/required/g) || []).length > 0;
    }
  },
  created() {
    if (this.optionType) {
      this.$store.dispatch(`${this.optionType}/options/index`);
    }
  }
};
</script>

<style module lang="scss">
.col-label {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
.col-input {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
</style>
