<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-file-input
      filled
      :error-messages="errors[0]"
      :name="name"
      :loading="uploading"
      @change="onFileChanged"
    >
      <!-- :placeholder="storedFile" -->
    </v-file-input>
  </ValidationProvider>
</template>

<script>
import axios from "@/entries/front/axios";

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String
    },
    label: {
      type: String
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    errorMessages: {
      type: Array
    },
    // TODO: 削除検討
    displayStoredFile: {
      type: Boolean,
      default: false
    },
    storedFile: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uploading: false,
      fileName: ""
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    onFileChanged(file) {
      if (file) {
        this.uploading = true;
        let formData = new FormData();
        formData.append("file", file);

        axios
          .postUpload("file", formData)
          .then((response) => {
            // if (response.data) {
            //   this.localValue = {
            //     ...response.data.data
            //   };
            // }
            this.uploading = false;
          })
          .catch(() => {
            this.uploading = false;
          });
      }
    },
    onRemove() {
      // this.fileName = "";
      // this.localValue = {
      //   fileName: "",
      //   hashName: ""
      // };
      // this.$nextTick().then(() => {
      //   this.$validator.validate(this.name);
      // });
    }
  }
};
</script>

<style module lang="scss"></style>
