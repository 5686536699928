<template>
  <div :style="{ width: '100%', display: 'flex' }">
    <ValidationProvider
      :style="{ width, 'min-width': '160px' }"
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      :vid="name"
    >
      <v-text-field
        v-model="localValue"
        class="password-field rounded-0"
        type="password"
        single-line
        height="48"
        clearable
        solo
        flat
        :error-messages="errors[0]"
        :name="name"
        :ref="refName"
        :hide-details="hideDetails"
        :background-color="backgroundColor"
      />
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: String
    },
    width: {
      type: String,
      default: "200px"
    },
    refName: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "#fff"
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss">
.password-field {
  .v-input__slot {
    min-height: 48px !important;
    &:before {
      border-color: transparent !important;
    }
  }
}
</style>
