<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-radio-group
      v-model="localValue"
      :error-messages="errors[0]"
      :name="name"
    >
      <template v-for="(option, index) in options">
        <v-radio :key="index" color="primary" :value="option[valueKey]">
          <div slot="label">
            <strong>{{ option[textKey] }}</strong>
            <p :class="$style['description']">{{ option.description }}</p>
          </div>
        </v-radio>
      </template>
    </v-radio-group>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {},
    options: {
      type: Array,
      required: true
    },
    textKey: {
      type: String,
      required: true
    },
    valueKey: {
      type: String,
      required: true
    },
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style module lang="scss">
.description {
  margin: 0;
}
</style>
