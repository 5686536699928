<template>
  <fragment>
    <template v-if="as === 'link'">
      <v-subheader :class="$style['display']">
        <a :href="displayOption(value).filePath" target="_blank">{{
          render(value)
        }}</a>
      </v-subheader>
    </template>
    <template v-else-if="as === 'html'">
      <v-subheader
        :class="$style['display']"
        v-html="joinedHtmls"
      ></v-subheader>
    </template>
    <template v-else-if="as === 'text'">
      <div :class="$style['text-display']" v-html="onlyText"></div>
    </template>
    <template v-else-if="as === 'image'">
      <v-img
        :src="displayOption(value).filePath || ''"
        :max-width="maxWidth"
        contain
      ></v-img>
    </template>
    <template v-else>
      <v-subheader :class="$style['display']" class="default-display">{{
        joinedValues
      }}</v-subheader>
    </template>
    <div v-if="remarks" :class="$style['remarks']">{{ remarks }}</div>
  </fragment>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    as: {
      type: String,
      defalut: "default"
    },
    render: {
      type: Function,
      default: (value) => value
    },
    option: {
      type: Function,
      default: () => ({
        filePath: "",
        fileName: ""
      })
    },
    maxWidth: {
      type: [String, Number],
      default: "500px"
    },
    remarks: {
      type: String,
      default: ""
    }
  },
  computed: {
    arrayValues() {
      return Array.isArray(this.value) ? this.value : [this.value];
    },
    joinedHtmls() {
      return this.arrayValues
        .map((value) => {
          return this.render(value);
        })
        .join("");
    },
    onlyText() {
      return this.arrayValues
        .map((value) => {
          if (value) {
            value = value.replace(/\n|\r\n|\r/g, "<br>");
          }
          return this.render(value);
        })
        .join("");
    },
    joinedValues() {
      return this.arrayValues.map((value) => this.render(value)).join(", ");
    }
  },
  methods: {
    displayOption(value) {
      return this.option(value) || {};
    }
  }
};
</script>

<style module lang="scss">
.display {
  font-size: $font-size-root;
  font-weight: 600;
  max-width: 600px;
  text-align: left;
  align-self: flex-start;
  background-color: #fff;
}

.remarks {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 24px;
  &:before {
    content: "※";
    margin-right: 4px;
  }
}
.text-display {
  font-size: $font-size-root;
  font-weight: 600;
  max-width: 600px;
  text-align: left;
  align-self: flex-start;
  padding: 0 16px;
}
</style>

<style scoped lang="scss">
.default-display {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
