<template>
  <ValidationProvider
    :style="{ width, 'min-width': '300px' }"
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    :vid="name"
  >
    <v-textarea
      class="textarea-field"
      v-model="localValue"
      rows="5"
      auto-grow
      filled
      single-line
      :error-messages="errors[0]"
      :name="name"
      background-color="#F3F5F6"
      :placeholder="placeholder"
    />
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    width: {
      type: String,
      default: "200px"
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss">
.textarea-field {
  .v-input__slot {
    &:before {
      border-color: transparent !important;
    }
  }
}
</style>
